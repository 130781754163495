<template>
  <div id="receiveManage">
    <header-nav :content="`${$t('pages_router_003')} / ${ $t('pages_router_006')}`" />
    <div class="common-card-body">
      <div class="edit-btn">
        <a-button
          v-if="themeData.electronicReceiptBatch"
          shape="round"
          class="btn-warning"
          style="margin-right: auto"
          :loading="downLoading"
          @click="downReceipt('')"
        >
          <template #icon><bars-outlined /></template>
          {{ downLoading ? $t('common_text_023') : $t('pages_receive_162') }}
        </a-button>
        <a-button
          shape="round"
          type="primary"
          :disabled="!canUpload"
          @click="$router.push({ path: '/receive/material_submit', query: { isAdvance: true }})"
        >
          <template #icon>
            <a-tooltip>
              <template #title>{{ $t('pages_receive_078') }}</template>
              <question-circle-outlined class="ic-tip" />
            </a-tooltip>
          </template>
          {{ $t('pages_receive_076') }}
        </a-button>
        <a-button
          shape="round"
          type="primary"
          ghost
          style="margin-left: 12px"
          :loading="exportLoading"
          @click="exportTradeFlow"
        >
          <template #icon><download-outlined /></template>
          {{ exportLoading ? $t('common_text_023') : $t('pages_receive_077') }}
        </a-button>
      </div>
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_067') }}: </span>
          <a-input
            v-model:value="searchQuery.payerName"
            allow-clear
            @pressEnter="loadReceiveFlows(1)"
            @blur="loadReceiveFlows(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_005') }}: </span>
          <a-select
            v-model:value="searchQuery.accountName"
            show-search
            allow-clear
            @change="loadReceiveFlows(1)"
          >
            <a-select-option
              v-for="(status, index) in simpleNameOpts"
              :key="index"
              :value="status.code"
            >{{ status.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_003') }}: </span>
          <a-input
            v-model:value="searchQuery.receiveNo"
            allow-clear
            @pressEnter="loadReceiveFlows(1)"
            @blur="loadReceiveFlows(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_155') }}: </span>
          <a-input
            v-model:value="searchQuery.applyNo"
            allow-clear
            @pressEnter="loadReceiveFlows(1)"
            @blur="loadReceiveFlows(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_068') }}: </span>
          <a-select
            v-model:value="searchQuery.status"
            allow-clear
            @change="loadReceiveFlows(1)"
          >
            <a-select-option
              v-for="status in statusOpts"
              :key="status.code"
              :value="status.code"
            >{{ status.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_013') }}: </span>
          <a-select
            v-model:value="searchQuery.receiveType"
            allow-clear
            @change="loadReceiveFlows(1)"
          >
            <a-select-option
              v-for="status in receiveTypeOpts"
              :key="status.code"
              :value="status.code"
            >{{ status.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_069') }}: </span>
          <a-range-picker
            v-model:value="searchQuery.dateOpts"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            @change="loadReceiveFlows(1)"
          />
        </div>
      </div>
      <div class="table-part">
        <div
          style="
            display: flex;
            margin-bottom: 24px;
          "
        >
          <div
            v-if="amountList.length !== 0"
            class="amount-area"
          >
            <span style="white-space: nowrap">{{ $t('pages_receive_070') }}:</span>
            <div class="amount-list">
              <a-tag
                v-for="item in amountList"
                :key="item.currency"
                style="
                  margin-bottom: 12px;
                  border: 0;
                "
              >
                <div class="flex-align">
                  <img :src="getCountryLogo(item.currency)">
                  <span>{{ item.amountText }} {{ item.currency }}</span>
                </div>
              </a-tag>
            </div>
          </div>
          <a-button
            v-if="gpiShow"
            type="primary"
            shape="round"
            style="margin: 0 18px 0 auto"
            @click="gpiView"
          >{{ $t('pages_receive_182') }}</a-button>
        </div>
        <a-table
          :scroll="{ x: 1500 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="flowList"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'edit'">
              <a-space style="width: 100%; justify-content: space-between">
                <a-button
                  v-if="record.status === '08'"
                  shape="round"
                  size="small"
                  class="btn-success"
                  @click="reApply(record, false, true)"
                >{{ $t('pages_receive_073') }}</a-button>
                <a-button
                  v-else
                  shape="round"
                  size="small"
                  class="btn-primary-empty-0"
                  @click="showDetail(record)"
                >{{ $t('pages_receive_009') }}</a-button>
                <a-button
                  v-if="record.status === '24'"
                  shape="round"
                  size="small"
                  type="primary"
                  danger
                  @click="settleApply(record)"
                >{{ $t('pages_receive_171') }}</a-button>
                <a-button
                  v-if="record.status === '26'"
                  shape="round"
                  size="small"
                  type="primary"
                  danger
                  @click="settleApply(record)"
                >{{ $t('pages_receive_173') }}</a-button>
                <a-button
                  v-if="record.status === '04'"
                  shape="round"
                  size="small"
                  type="primary"
                  @click="reApply(record)"
                >{{ $t('pages_receive_074') }}</a-button>
                <a-button
                  v-if="record.status === '03'"
                  shape="round"
                  size="small"
                  danger
                  type="primary"
                  @click="reApply(record, true)"
                >{{ $t('pages_receive_075') }}</a-button>
                <a-button
                  v-if="record.status === '02' && themeData.electronicReceipt"
                  shape="round"
                  size="small"
                  class="btn-warning"
                  @click="downReceipt(record.applyNo)"
                >{{ $t('pages_receive_161') }}</a-button>
              </a-space>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
    <settle-apply
      v-model:visible="settleVisible"
      :receive-info="receiveInfo"
      @refresh="loadReceiveFlows"
    />
    <gpi-modal
      v-model:visible="gpiVisible"
    />
    <receive-detail
      v-model:visible="visible"
      :receive-info="receiveInfo"
    />
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import TablePage from '@/components/table-page'
import SettleApply from './components/settle-apply'
import ReceiveDetail from './components/receive-detail'
import GpiModal from './components/gpi-modal'
import { reactive, toRefs, computed, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { _receive } from '@/api/index'
import { getAttrStatusList, getTradeStatusName, parseMoney, getCountryLogo } from '@/util'
import i18n from '@/locale/index'
export default {
  name: 'ReceiveManage',
  components: {
    'header-nav': HeaderNav,
    'table-page': TablePage,
    'settle-apply': SettleApply,
    'receive-detail': ReceiveDetail,
    'gpi-modal': GpiModal
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const state = reactive({
      themeData: inject('$themeData'),
      loading: true,
      visible: false,
      gpiVisible: false,
      gpiShow: false,
      settleVisible: false,
      canUpload: false,
      exportLoading: false,
      downLoading: false,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        payerName: '',
        accountName: '',
        receiveNo: route.query.receiveNo || '',
        applyNo: '',
        status: '',
        receiveType: '',
        dateOpts: []
      },
      total: 0,
      vaList: [],
      simpleNameOpts: [],
      flowList: [],
      amountList: [],
      receiveInfo: {},
      columns: [
        { key: 'receiveTime', dataIndex: 'receiveTime', title: i18n.global.t('pages_receive_069'), width: 180 },
        { key: 'receiveAmtText', dataIndex: 'receiveAmtText', title: i18n.global.t('pages_receive_070'), width: 180 },
        { key: 'feeAmtText', dataIndex: 'feeAmtText', title: i18n.global.t('pages_receive_071'), width: 180 },
        { key: 'entryAmtText', dataIndex: 'entryAmtText', title: i18n.global.t('pages_receive_072'), width: 180 },
        { key: 'receiveAccount', dataIndex: 'receiveAccount', title: i18n.global.t('pages_receive_003'), width: 200, ellipsis: true },
        { key: 'payerName', dataIndex: 'payerName', title: i18n.global.t('pages_receive_067'), width: 200, ellipsis: true },
        { key: 'accountName', dataIndex: 'accountName', title: i18n.global.t('pages_receive_005'), width: 200, ellipsis: true },
        { key: 'statusText', dataIndex: 'statusText', title: i18n.global.t('pages_receive_068'), width: 150 },
        { key: 'transTypeText', dataIndex: 'transTypeText', title: i18n.global.t('pages_receive_013'), width: 150 },
        { key: 'applyNo', dataIndex: 'applyNo', title: i18n.global.t('pages_receive_155'), width: 200, ellipsis: true },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 328 : 220 }
      ]
    })

    state.receiveTypeOpts = computed(() => {
      return getAttrStatusList('receiveTransType')
    })

    state.statusOpts = computed(() => {
      return getAttrStatusList('ReceiveTradeFlowStatus')
    })

    const init = async () => {
      await loadAllVAs()
      loadGpiAuth()
      loadUploadUsed()
      loadReceiveFlows()
    }

    const gpiView = () => {
      state.gpiVisible = true
    }

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadReceiveFlows()
    }

    const downReceipt = async (applyNo) => {
      let data = {}
      if (applyNo) {
        data.applyNo = applyNo
      } else {
        state.downLoading = true
        const { dateOpts } = state.searchQuery
        data = Object.assign({}, state.searchQuery, {
          createTimeStart: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
          createTimeEnd: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
        })
      }
      await _receive.downReceiveReceipt(data)
      state.downLoading = false
    }

    const exportTradeFlow = async () => {
      state.exportLoading = true
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        createTimeStart: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        createTimeEnd: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      await _receive.exportTradeFlow(query)
      state.exportLoading = false
    }

    const showDetail = async (record) => {
      const res = await _receive.getSettleInfo({ applyNo: record.applyNo })
      if (res.data.succ) {
        const { data } = res.data
        state.receiveInfo = Object.assign({}, record, {
          settleSubmitAt: data.createTime,
          merRate: data.merRate,
          buyCur: data.buyCur,
          buyAmountText: data.buyAmount ? parseMoney(data.buyAmount, data.buyCur) : '-'
        })
      } else {
        state.receiveInfo = record
      }
      state.visible = true
    }

    const settleApply = (record) => {
      state.receiveInfo = record
      state.settleVisible = true
    }

    const reApply = async (record, reset, update) => {
      const query = {}
      if (reset) query.isReset = true
      if (update) {
        const vaInfo = state.vaList.find(item => item.accountNo === record.receiveAccount)
        query.isUpdate = true
        query.vaId = vaInfo.id
      } else {
        const res = await _receive.getAccountLimit({ applyNo: record.applyNo })
        if (res.data.succ) {
          let currency = record.currency
          if (['00', '10'].includes(record.transType)) currency = 'CNY'
          record.fillAmt = res.data.data || 0
          record.fillAmtText = `${currency} ${record.fillAmt ? parseMoney(record.fillAmt, currency) : `0.00`}`
        }
      }
      sessionStorage.setItem('materialInfo', JSON.stringify(record))
      if (['00', '10'].includes(record.transType)) {
        query.applyNo = record.applyNo
        query.transType = record.transType
        router.push({ path: '/receive/material_submit/e_commerce', query })
      } else {
        router.push({ path: '/receive/material_submit/t_trade', query })
      }
    }

    const loadGpiAuth = async () => {
      const res = await _receive.getMerProInfo({ proId: 17 })
      if (res.data.succ) state.gpiShow = res.data.data && res.data.data.status === 'Y'
    }

    const loadUploadUsed = async () => {
      const res = await _receive.getUploadUsed()
      if (res.data.succ) {
        state.canUpload = res.data.data
      }
    }

    const loadAllVAs = async () => {
      const simpleNameOpts = []
      const res = await _receive.getAllVAs()
      if (res.data.succ) {
        sessionStorage.setItem('vaList', JSON.stringify(res.data.data))
        res.data.data.map(item => {
          if (item.simpleName) simpleNameOpts.push({ code: item.simpleName, name: item.simpleName })
        })
        state.simpleNameOpts = simpleNameOpts
        state.vaList = res.data.data
      }
    }

    const loadReceiveFlows = async (pageNum) => {
      state.loading = true
      if (!state.searchQuery.dateOpts) state.searchQuery.dateOpts = []
      if (pageNum) state.searchQuery.pageNum = pageNum
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        createTimeStart: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        createTimeEnd: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      const res = await _receive.getReceiveFlows(query)
      if (res.data.succ) {
        const { data } = res.data
        state.flowList = data.flowList.list.map(item => {
          const vaInfo = state.vaList.find(va => va.accountNo === item.receiveAccount)
          return Object.assign({}, item, {
            feeAmtText: item.status === '08' ? '-' : `${item.feeCurrency} ${parseMoney(item.feeAmount, item.feeCurrency)}`,
            entryAmtText: item.status === '08' ? '-' : `${item.entryCurrency} ${parseMoney(item.entryAmount, item.entryCurrency)}`,
            receiveAmtText: item.status === '08' ? '-' : `${item.currency} ${parseMoney(item.receiveAmount, item.currency)}`,
            payerName: item.status === '08' ? '-' : item.payerName,
            receiveTime: item.status === '08' ? '-' : item.receiveTime,
            statusText: getTradeStatusName(item.status, 'ReceiveTradeFlowStatus'),
            transTypeText: getTradeStatusName(item.transType, 'receiveTransType'),
            accountName: vaInfo ? vaInfo.simpleName : ''
          })
        })
        state.amountList = data.amountList.map(item => {
          return Object.assign({}, item, {
            amountText: parseMoney(item.receiveAmount, item.currency)
          })
        })
        state.total = data.flowList.total
      }
      state.loading = false
    }

    init()

    return {
      getCountryLogo,
      getAttrStatusList,
      reApply,
      gpiView,
      settleApply,
      showDetail,
      updatePage,
      downReceipt,
      exportTradeFlow,
      loadGpiAuth,
      loadReceiveFlows,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#receiveManage {
  .common-card-body {
    .edit-btn {
      display: flex;
      justify-content: flex-end;
      padding: 0 18px;
      .ic-tip {
        pointer-events: auto !important;
      }
    }
    .table-part {
      margin-top: 12px;
      .amount-area {
        display: flex;
        padding-left: 16px;
        .amount-list {
          margin-left: 8px;
          text-align: left;
          img {
            width: 18px;
            height: 12px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>

