<template>
  <a-modal
    :visible="visible"
    :title="$t('pages_gpi_001')"
    :width="800"
    :mask-closable="false"
    @cancel="$emit('update:visible', false)"
  >
    <div class="flex-align ant-round-form">
      <span class="part-label">{{ $t('pages_gpi_006') }}: </span>
      <a-select
        v-model:value="vaAcct"
        show-search
        allow-clear
        style="
          width: 250px;
          margin-left: 5px;
        "
        option-filter-prop="label"
        @change="loadGpi"
      >
        <a-select-option
          v-for="(v, k) in vaList"
          :key="k"
          :value="k"
          :label="`${k}-${v}`"
        >{{ `${k}-${v}` }}</a-select-option>
      </a-select>
    </div>
    <a-skeleton
      v-if="loading"
      active
    />
    <gpi-detail
      v-else
      :list="gpiList"
    />
    <template #footer>
      <a-button
        type="primary"
        @click="$emit('update:visible', false)"
      >{{ $t('common_text_024') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
import GpiDetail from '@/components/gpi-detail'
import { ref, watchEffect } from 'vue'
import { message } from 'ant-design-vue'
import { _receive } from '@/api'
import { parseMoney, getTradeStatusName } from '@/util'
export default {
  name: 'GpiModal',
  components: {
    'gpi-detail': GpiDetail
  },
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props) {
    const loading = ref(true)
    const vaAcct = ref(null)
    const vaList = ref([])
    const gpiList = ref([])

    watchEffect(() => {
      if (props.visible) loadGpi()
    })

    const loadVa = async () => {
      const res = await _receive.supportGpiVa()
      if (res.data.succ) vaList.value = res.data.data
    }

    const loadGpi = async () => {
      loading.value = true

      const query = { tradeType: 'receive' }
      if (vaAcct.value) query.vaAcct = vaAcct.value

      const res = await _receive.queryGpiDetail(query)
      if (res.data.succ) {
        gpiList.value = res.data.data.map(item => {
          const gpiDetail = item.gpiDetail.sort((a, b) => a.sort - b.sort)
          return Object.assign({}, item, {
            title: getTradeStatusName(item.gpiStatus, 'gpiStatus'),
            tradeAmtText: `${parseMoney(item.tradeAmt)} ${item.tradeCur}`,
            subList: gpiDetail.map(it => {
              return Object.assign({}, it, {
                title: getTradeStatusName(it.titleCode, 'gpiStatus')
              })
            })
          })
        })
      } else {
        message.destroy()
        gpiList.value = []
        message.error(res.data.resMsg)
      }

      loading.value = false
    }

    const init = () => {
      loadVa()
    }

    init()

    return {
      loading,
      vaAcct,
      vaList,
      gpiList
    }
  }
}
</script>
